:root {
    --font:'Poppins', sans-serif;;
    --white: #ffffff;
  }
  *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* background-color: #ffffff; */
        
    }
    .iscomp{
        width: 100%;
    }
    body{
        background-color: #fff;
    }
    .pageheadertitle{
        font-size: 17px;
        font-weight: 500;
        font-family: var(--font);
        letter-spacing: 1px;
        color: #000;
    }
    .ant-message { 
        top: 0px; 
    }
    .ctitel{
        height: 30px;
        text-align: left !important;
        font-size: 12px;
        font-weight: 400;
        font-family: var(--font);
        font-size: 14px;
        letter-spacing: 0.5px;
        color: rgb(7, 7, 7); 
    }
    /* ::-webkit-scrollbar {
        width: 0;  
        background: transparent; 
    } */
    .ant-result {
         padding:0px; 
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #f67225;
        font-family: var(--font);
        letter-spacing: 1px;
        font-weight: 500;
    }
    .ant-tabs-ink-bar {
        position: absolute;
        pointer-events: none;
    }
    .ant-tabs-tab {
        color: #a5a4a3;

        font-family: var(--font);
        letter-spacing: 1px;
        font-weight: 500;      }
      
      /* .ant-tabs-tab-active {
        color: #eb9c0a !important;
      } */

      .slick-prev {
        top: 40%;
        background: #fff;
        left: 3% !important;
        z-index: 1;
      }
      .slick-next {
        top: 40%;
        background: #fff;
        right: 3% !important;
        z-index: 1;
      }
      .slick-prev::before, .slick-next::before {
        font-family: 'slick';
        font-size: 20px;
        line-height: 1;
        opacity: 1;
        color: #f67225;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .slick-slide > div {
        margin: 0 5px;
      }
      .slick-list {
        margin: 0 -5px;
      }
     