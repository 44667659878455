.welcome_continer{
    display: flex;
    width: 100%;
  
    text-align: center;
    align-items: center;
    justify-content:flex-end;
    background-color: white;
    flex-direction: column;
   
}
.welcome_header{
    font-size:25px;
    font-weight: bold;
}
.welcome_body{
    padding-left: 5px;
    padding-right: 5px;
  letter-spacing: 1px;
  font-size:20px;
  font-weight: 500;
  color:#858583;
}
.list_continer{
    display: flex;
    height: 220px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.list_titel{
   display: flex;
   height: 20%;
   width: 100%;
   font-size: 18px;
   letter-spacing: 1px;
   font-family: var(--font);
   align-items: center;
   justify-content: space-between;
   font-weight: 500;
   color: black;
  margin-top: 10px;
  padding-left: 3px; 
  border-left-style: solid ;
  border-left-color: #f67225;
   
  
}
.list_body{
   display: flex;
   height: 60%;
   width: 100%;
   overflow: scroll;
}
.list_bodycontiner{
   display: flex;
   height:100%;
   width: 100px;
   margin-right: 7px;
   align-items: center;
   justify-content: center;
 
}
.list_downbodycontiner{
   display: flex;
   height:100%;
   width: 330px;
   align-items: center;
   justify-content: center;
  
}

.imges{
   height:100%;
   width: 100px;
  
   background-color:#EEFBF9;
   
}
.list_fooder{
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 18px;
    letter-spacing: 1px;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    color: rgb(36, 164, 238);
}
.journalCover{
   height:100%;
   width: 100px;
   margin-right: 7px;
   background-size: 100% 100%;
}
.journaimg{
   height:100%;
   width: 100px;
   margin-right: 7px;
   background-size: 100% 100%;
}
.pubName
{
   padding-left: 5px;
   width: 100px;
   color:#ff7600;
   font-size: 10px;
   margin: 0px 5px 0px 5px;
}

.subject_continer{
    display: flex;
    width: 100%;
    height: 100px;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   background-color: white;
   
}
.subject_titel{
   display: flex;
   width: 100%;
   font-size: 18px;
   letter-spacing: 1px;
   align-items: center;
   font-weight: 500;
   color: black;
  
}
.subject_subtitel{
   display: flex;
   width: 100%;
   font-size: 14px;
   letter-spacing: 1px;
   align-items: center;
   font-weight: 300;
   color: rgb(100, 99, 99);

}
.subject_body{
   display: flex;
   height: 80%;
   width: 100%;
   overflow: scroll;
}
.subject_item{
   display: flex;
   height:100%;
   margin-right: 7px;
   align-items: center;
   justify-content: center;
 
}
.listitem{
   padding: 10px;
   letter-spacing: 1px;
   background-color: #F0F0F0;
   color: #f67225;
   text-align: center;
   align-items: center;
   justify-content: center;
   border-radius: 10px;
   font-size: 15px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-family: var(--font);

}
.listitem:hover{
   background-color: #f67225;
   color:#ffffff;
   font-family: var(--font);

}
.navheader{
   display: flex;
   flex-direction: row;
   background-color:white;
   height: 50px;
   align-items: center;
   justify-content:space-between;
   box-shadow: 0px 0px 4px 2px #dbdada;

 }
.menucontiner{
   display: flex;
   height: 40px;
   align-items:center;
   justify-content: flex-end;
   margin-right: 10px;
}


.notification_box{
    padding: 5px 5px 7px 5px;
    height: 40px;
 
}
.wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
   width: 100%;
}

.search_box{
	position: relative;
   display: flex;
   width: 95%; 
   margin-top: 5px;

}

.search_box .search_btn{
   background-color: #f67225;
   border-bottom-right-radius: 5px;
   border-top-right-radius: 5px;
	width: 40px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	cursor: pointer;

}

.search_box .input_search{
	outline: none;
	border: 0;
	width: 100%; 
	height: 30px;
	color: rgb(58, 57, 57);
   border-bottom-left-radius: 5px;
   border-top-left-radius: 5px;
   background-color: #ECEDEF;

}

::placeholder {
   letter-spacing: 1px;
  color: rgb(150, 148, 148);
}

::-webkit-input-placeholder {
  color: rgb(148, 146, 146);
}

:-ms-input-placeholder {
  color: rgb(121, 118, 118);
}



/* usercard */
.menu-continer{
   display: flex;
   flex-direction: column;
   /* align-items: center; */
   margin-top: 10px;
   text-decoration: none;
   border-top: 1px solid #ddd;
   padding: 20px;
   transition: 00.5s;
   
}
.menu-continer .icon{
   margin-right: 10px;
   font-size: 15px;
   color: chocolate;
   transition: 00.5s;
   
}
.menu-continer a{
   text-decoration: none;
   color: chocolate;
   transition: 00.5s;
}

.logout{
    margin-top: 10px;
}
.userName{
   font-family: var(--font);
    font-weight: 500;
}
.userEmail{
   font-family: var(--font);
   color: rgb(121, 118, 118);
}
.userPopup
{
    min-width: 300px;
    background-color: #fff;
    text-align: center;
    /* padding: 20px;
    border-radius: 2px;
    box-shadow: 1px 1px 3px 0px rgba(156,146,146,0.75);
    -webkit-box-shadow: 1px 1px 3px 0px rgba(156,146,146,0.75);
    -moz-box-shadow: 1px 1px 3px 0px rgba(156,146,146,0.75); */
}
.ratecard{
   width: 100%;
    background-color: #fff;
    text-align: center;
    /* padding: 20px;
    border-radius: 2px;
    box-shadow: 1px 1px 3px 0px rgba(156,146,146,0.75);
    -webkit-box-shadow: 1px 1px 3px 0px rgba(156,146,146,0.75);
    -moz-box-shadow: 1px 1px 3px 0px rgba(156,146,146,0.75); */
}
.userImg img
{
    width: 90px;
    margin:0 auto;
    padding: 10px;
}
.btntext{
   font-family: var(--font);
   color: white;
}

/* d_card */
.d_card{
      display: flex;
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;
}

.d_body{
   display: flex;
   height: 100%;
   width: 100%;
   overflow: scroll;
   scroll-behavior: smooth;
}
.d_continer{
   display: flex;
   height:100%;
   align-items: center;
   justify-content: center;
}
.d_continerbody{
   margin: 5px;
   width:100%;
   padding: 5px;
   box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;  
   background-color:#ffffff;
   align-items: center;
display: flex;
flex-direction: column;
justify-content: center;
}
.ctitel{
   
   
   bottom: 0px;
   padding: 0px 2px 0px 2px;
   font-size: 13px;
   color: rgb(102, 101, 101);
   
   
}

.d_img{
   display: 'flex';
   align-items: 'center';
   justify-content: center;

}
.dimg{
   height: 230px;
   /* height: 200px; 
   min-width: 130px;
    width:auto ; */
   /* border-radius:5px; */
   background-color: #EEFBF9;
   /* box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75); */
    z-index: 1;
}
.dimg-l{
   height: 230px; 
   min-width: 130px;
    width:auto ;
   /* border-radius:5px; */
   background-color: #EEFBF9;
   /* box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 6px 5px -5px rgba(0,0,0,0.75); */
    z-index: 1;
}
.d_namecontiner{
   display: flex;
   align-items: center;
   justify-content: center;
}
.d_name{
    /* display: flex; */
    width: 140px;
    align-items:center;
    justify-content: center;
    text-align: center;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #585757;
}
.d_readericon{
     font-size: 20px;
     color:#ff7600;
     margin-left: 5px;
 }
 .rbut{
    display: none;
 }
 .viewtext{
   letter-spacing: 1px;
    font-family: var(--font);
    font-size: 10px;
    color:#fff;
    border-radius: 17px;
    padding: 5px 5px 5px 5px;
    background-color: #ff7600;
 }
 .menu{
    align-items: center;
    justify-content: center;
    display: none;
    margin-right: 20px;
    flex-direction: column;
  
 }

 .profile{
   align-items: center;
   justify-content: center;
   display: flex;
  flex-direction: column;
  cursor: pointer;
}
.menutext{
   letter-spacing: 1px;
}


.profile:hover {
   color: #f67225;
 }
 .menuicon{
    font-size: 20px;
    margin-left: 6px;
    color: #f67225
 }
 .search_container{
    height: 100%;
    width: 100%;
    display:block;
    align-items: center;
    justify-content: center;
    background-color: #717070;
 }

 .navlogo{
   width: 150px;
   height: 35px;
}









 
 @media only screen and (min-width: 600px) {
   /* For tablets: */
   .dimg{
      width: auto;
      height: 250px;
      margin-right: 10px;
      border-radius:5px;
      background-color: #EEFBF9;
   }
   .d_name{
      /* display: flex; */
      width: 150px;
      align-items:center;
      justify-content: center;
      text-align: center;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      color: #585757;
  }
  .listitem{
     letter-spacing: 1px;
   padding: 5px;
   background-color: #F0F0F0;
   text-align: center;
   align-items: center;
   justify-content: center;
   border-radius: 20px;
   font-size: 15px;
    color: #717070;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    font-family: var(--font);
}
  
  
   .rbut{
      display:flex;
   }
   .menucontiner{
      display: flex;
      height: 40px;
      width: 50%;
      align-items:center;
      justify-content:space-between;
      margin-right: 10px;
     
   }
  
   .menu{
      align-items: center;
      justify-content: center;
      display: flex;
      margin-right: 20px;
      flex-direction: column;
     
    
   }
   .search_box{
      position: relative;
      display: flex;
      width: 90%; 
      margin-top: 0px;
   
   }
   .search_box .search_btn{
      background-color: #f67225;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      width: 50px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      cursor: pointer;
   
   }
   
   .search_box .input_search{
      outline: none;
      border: 0;
      width: 100%; 
      height: 40px;
      color: rgb(58, 57, 57);
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      background-color: #ECEDEF;
   
   }
   .navlogo{
      width: 100px;
      height: 35px;
   }
  
  
   
 }
 .searchinput{
   height: 30px;
   width: 100%;
   margin-right: 15px;
   position: relative;

}
.searchinput-s{
   height: 45px;
   margin-top: 10px;
   margin-left: 10px;
   margin-right: 10px;
   position: relative;

}
.searchboxfiled-s { 
   width: 100%; 
   height: 45px;
   padding-left: 40px;
   padding-right: 30px;
   display: inline-block; 
   border: 1px solid var(--bg2-color); 
   box-sizing: border-box; 
   background-color: #F0F0F0;
   font-family: var(--font);
   letter-spacing: 0px;
   color: #000;

} 
.searchicon-s{
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   height: 100%;
   width: 25px;
   left: 0px;
   top: 0px;
   margin-left: 10px;
   color: rgb(97, 96, 96);
}
.loadingicon-s{
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   height: 100%;
   width: 25px;
   right: 0px;
   top: 0px;
   margin-left: 10px;
   color: rgb(97, 96, 96);
}

.searchicon{
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   height: 100%;
   width: 25px;
   right: 0px;
   top: 0px;
   color: rgb(97, 96, 96);
}
.searchboxfiled { 
   width: 100%; 
   height: 30px;
   padding-left: 3px;
   padding-right: 30px;
   display: inline-block; 
   border: 1px solid var(--bg2-color); 
   box-sizing: border-box; 
   background-color: #F0F0F0;
   font-family: var(--font);
   letter-spacing: 0px;
   color: #000;

} 
.viewall-con{
   display: flex;
   height: 35px;
   align-items: center;
   justify-content: center;
}
 @media only screen and (min-width: 768px) {
   /* For desktop: */
   .dimg{
      cursor:pointer;
      width: auto;
      height: 250px;
      margin-right: 10px;
      border-radius:5px;
      background-color: #EEFBF9;
   }
   .d_name{
      /* display: flex; */
      width: 200px;
      align-items:center;
      justify-content: center;
      text-align: center;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      color: #585757;
  }
  .listitem{
     letter-spacing: 1px;
   padding: 5px;
   background-color: #F0F0F0;
   text-align: center;
   align-items: center;
   justify-content: center;
   border-radius: 20px;
   font-size: 15px;
    color: #717070;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    font-family: var(--font);

}
   
   .rbut{
      display:flex;
   }
   .menucontiner{
      display: flex;
      height: 40px;
      width: 50%;
      align-items:center;
      justify-content:flex-end;
      margin-right: 10px;
      margin-top: 10px;
     
   }
   
   .menu{
      align-items: center;
      justify-content: center;
      display: flex;
      margin-right: 20px;
      flex-direction: column;
      cursor: pointer;
   }
   .search_box{
      position: relative;
      display: flex;
      width: 70%; 
      margin-top: 0px;
   
   }
   .search_box .search_btn{
      background-color: #f67225;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      width: 50px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      cursor: pointer;
   
   }
   
   .search_box .input_search{
      outline: none;
      border: 0;
      width: 100%; 
      height: 40px;
      color: rgb(58, 57, 57);
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      background-color: #ECEDEF;
   
   }
   .navlogo{
      width: 150px;
      height: 35px;
   }
   
   
  
 }


